import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageHeader from '../PageComponents/PageHeader';
import '../../Content/css/faq.css'
import Html from 'react-html-parser';
export default class Faq extends Component {
	constructor(props) {
	  super(props);
	  this._isMounted = false;
	  this.state = {
	  	gravContent: this.props.pageContext.frontmatter,
	  	headContent: this.props.pageContext.head,
	  	questions: this.props.pageContext.questions,
	  	searchField: '',
	  	filteredQuestions: [],
	  	wrapperStyle:{},
	  	loading: true,
	  };
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount(){
		this._isMounted = true
		if(this._isMounted){
			this.setState({loading: false})
			window.scrollTo(0,0);
		}
	}
	filterItems(items){
		let filteredItems = this.state.questions;
		filteredItems = filteredItems.filter((item) =>{
			let questions = item.question.toLowerCase() + item.answer.toLowerCase()
			return questions.indexOf(items.toLowerCase()) !== -1
		})
		this.setState({ filteredQuestions: filteredItems})
	}
	handleCancelSearch(){
		this.setState({ searchField: ''})
	}
	handleSearchFilter(e){
		let searchField = this.state.searchField;
		this.setState({ searchField: e.target.value })
		if(searchField.length > 0){
			this.filterItems(e.target.value);	
		}
	}
	render() {
		let list = this.props.pageContext.questions;
		let filteredList = this.state.filteredQuestions;
		let filterStarted = this.state.searchField.length > 0;
		let dataFound = this.state.filteredQuestions.length > 0;
		let questions = list.map((item, key) =>
		   <div key={key} className="indentedList">
		   		<h5>{item.question}</h5>
		   		<div><span className="flaticon-right-arrow"></span>{Html(item.answer)}</div>
		   </div>
		);
		let filteredQuestions = filteredList.map((item, key) =>
		   <div key={key} className="indentedList">
		   		<h5>{item.question}</h5>
		   		<div><span className="flaticon-right-arrow"></span>{Html(item.answer)}</div>
		   </div>
		);
		const {title,keyWords,facebookTitle,facebookDesc,shareImg,googleDesc} = this.props.pageContext.head,
		{location} = this.props;

		return (
			<main>
				<Helmet>
					<title>Products | New Image Keto | Alpha Lipid™ SDII Programme</title>	
					{googleDesc&&<meta name="description" content={googleDesc}/>}
					{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					{location&&<meta property="og:url" content={location.href} />}
					<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
					<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
					<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
					<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
					<meta property="og:image" content={shareImg} />
					<meta property="og:image:url" content={shareImg}/>
					<meta name="twitter:image" content={shareImg}/>
					<meta name="twitter:card" content={shareImg}/>
				</Helmet>
				<div className="pageGrid clearfix">
					<section>
						<PageHeader mainHeading="Frequently<br/> Asked Questions" subHeading="How can we help you?"/>
						<div className="text-center mt-4">
							<label htmlFor="search-field">Find an answer</label>
						</div>
						<div className="searchFilter">
							<span className="flaticon-search"></span>
							<input type="text" 
								onChange={this.handleSearchFilter.bind(this)} 
								value={this.state.searchField}
								className="searchField"
								placeholder="find an answer"
								id="search-field"
								name="search-field"
							/>
							{filterStarted&& <button className="flaticon-close" onClick={this.handleCancelSearch.bind(this)}>
								<span className="hidden">cancel</span>
							</button>}
						</div>
						{filterStarted&&
							<div>
								{dataFound?
									null:
									<div className="searchFilterNoResults" 	style={this.state.wrapperStyle}>
										<h3>No questions/answers found</h3>
									</div>
								}
							</div>
						}
					</section>
					<section className="faqSection">
						{filterStarted?
							<div>
								{dataFound? <div>{filteredQuestions}</div>:null}
							</div>
								:
							<div>{questions}</div>
						}
					</section>
				</div>
			</main>
		);
		
		
	}
}
